<template>
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/index/companyIntro16.png" alt="" />
    <div class="infoagroup">
      <router-link to="companyInfoItem" v-for="(item,index) in newlist" :key="index">
        <div class="li clearfix">
          <img :src="item.img" alt="">
          <div class="new">
            <div class="newtitle">{{item.title}}</div>
            <div class="newtime">{{item.time}}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <!-- 企业资讯 -->
  <div class="main" v-else>
    <!-- 横幅图 -->
    <img src="../../assets/img/information/img(1).png" alt="" />
    <!-- 内容主体 -->
    <div class="contentMain">
      <!-- 内容和列表 -->
      <div class="contentList">
        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(2).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>科技部将开展国家级科技企业孵化器认定</h4>
                <h4>2021.11.08</h4>
              </div>
              <p>
                11月8日消息，科技部火炬中心通知，将开展2021年度国家级科技企业孵化器认定工作。根据通知，参加本年度申报的孵化机构注册成立和实际运营
                时间须在2018年10月31日之前。各省级科技主管部门将于2021年12月1日前推荐该地区国家级科技企业孵化器。
              </p>
            </div>
          </router-link>
        </div>

        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(3).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>2021中国大健康产业峰会在深圳举办</h4>
                <h4>2021.11.05</h4>
              </div>
              <p>
                11月5日，2021年（第五届）中国大健康产业峰会在深圳落幕。大会围绕“‘健康中国’新10年
                开启创新2.0时代”这一主题，汇集监管机构、行业
                专家、产业领袖、技术先锋和投资机构等代表嘉宾，围绕中国医疗健康行业大变局、中国医药创新2.0时代的机遇与挑战、癌症早筛早诊早治及药物
                可及性等多个时下热点话题展开对话交流，共同探路未来。
              </p>
            </div>
          </router-link>
        </div>

        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(4).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>亿联体：数字化让“大健康”产业更健康</h4>
                <h4>2021.12.11</h4>
              </div>
              <p>
                今年突如其来的新冠肺炎疫情，使得许多企业迎来“寒冬”，经历了最为艰难的一年，但同时也有不少企业逆风翻盘，实现了新的飞跃。今
                年3月成功落地园区的浙江亿联体科技有限公司项目就是其中一员。
              </p>
            </div>
          </router-link>
        </div>

        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(5).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>中欧医疗健康国际合作分会近日成立</h4>
                <h4>2019.07.04</h4>
              </div>
              <p>
                近日，由我国著名心脏病学家高润霖院士任名誉会长，青年心脏病学家卢长林教授任会长的中国欧洲经济技术合作协会医疗健康国际合作分
                会（简称“中欧医疗健康国际合作分会”）日前成立。
              </p>
            </div>
          </router-link>
        </div>

        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(6).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>以医为本，跨界融合，创新发展</h4>
                <h4>2018.12.25</h4>
              </div>
              <p>
                2018年12月23日上午，首届医+论坛在上海奉贤开幕。本届论坛由商务部中国欧洲经济技术合作协会医疗健康国际合作分会主办，北京永大
                税务师事务所有限公司协办，上海亿联体创业孵化器管理有限公司承办。。
              </p>
            </div>
          </router-link>
        </div>

        <div class="contentItem">
          <div class="itemLeft">
            <router-link to="/companyInfoItem">
              <img src="../../assets/img/information/img(7).png" alt="" />
            </router-link>
          </div>
          <router-link to="/companyInfoItem">
            <div class="itemRight">
              <div class="itemTop">
                <h4>
                  加速新兴产业升级！重庆江津综保区首次引进多个医疗器械产业项目
                </h4>
                <h4>2018.10.24</h4>
              </div>
              <p>
                10月23日上午，重庆江津区传来好消息，在上午举行的江津区第三季度招商引资项目集中签约仪式上，共有27个项目总投资达323亿元落户江津，引
                资总额较去年第三季度同比增长逾30％。加上前两季度的招商签约项目，前三季度江津已经吸引了上千亿元的投资。
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pageShow">
      <div class="page">
        <!-- <span><</span> -->
        <span>1</span>
        <!-- <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>...</span>
        <span>6</span>
        <span>></span>
        <span>6条/页</span> -->
      </div>
    </div>
  </div>
</template>


<script>
export default {
  
  data() {
    return {
      newlist:[
        {
          img:require('../../assets/img/index/h5img8.png'),
          title:"科技部将开展国家级科技企业孵化 器认定工作",
          time:"2021.11.08"
        },
        {
          img:require('../../assets/img/index/h5img9.png'),
          title:"2021中国大健康产业峰会在深圳成 功举办",
          time:"2021.11.05"
        },
        {
          img:require('../../assets/img/index/h5img10.png'),
          title:"亿联体：数字化让“大健康”产业 更健康 拷贝",
          time:"2020.12.11"
        },
        // {
        //   img:require('../../assets/img/index/h5img8.png'),
        //   title:"科技部将开展国家级科技企业孵化 器认定工作",
        //   time:"2021.11.08"
        // },
        // {
        //   img:require('../../assets/img/index/h5img9.png'),
        //   title:"2021中国大健康产业峰会在深圳成 功举办",
        //   time:"2021.11.05"
        // },
        // {
        //   img:require('../../assets/img/index/h5img10.png'),
        //   title:"亿联体：数字化让“大健康”产业 更健康 拷贝",
        //   time:"2020.12.11"
        // },
      ]
    };
  },
  methods: {
    // infoDetailFn (e) {
    //   console.log(e.target);
    //   if(e.target) {
    //     this.$router.push('/companyInfoItem')
    //   }
    // }
    
  },
};
</script>

<style scoped lang="stylus">
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }
      .infoagroup{
        display: block
        width: 6.8rem;
        background: #FFFFFF;
        border-radius: 0.1rem;
        margin:0 auto;
        border-top:0.3rem solid #f5f5f5;
        border-bottom:0.4rem solid #f5f5f5;
        overflow: hidden;

        a{
          display: block;
          margin:0 auto;
          .li{
            border-bottom:0.01rem solid #EAEAEA;
            margin:0 0.22rem
            padding:0.31rem 0
            
            img{
              display block
              width: 2.48rem
              height: 1.28rem
              float: left
              margin-right: 0.25rem
            }

            .new{
              display: block
              float: left

              .newtitle{
                width: 3.6rem;
                height: 0.58rem;
                font-size: 0.24rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                line-height: 0.35rem;
              }

              .newtime{
                font-size: 0.24rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #666666;
                line-height: 0.39rem;
                margin-top:0.3rem
              }

            }
          }
        }
        :last-child{
          .li{
            border-bottom:0
          }
        }
      }
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      margin-top: 100px;
      font-family: Source Han Sans CN;
      color: #333333;

      img {
        width: 100%;
      }

      .contentList {
        width: 1280px;
        margin: 110px auto 20px;

        .contentItem {
          display: flex;
          justify-content: space-between;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 0px 20px #ccc;

            a {
              .itemRight {
                .itemTop {
                  h4 {
                    color: #0085D0;
                  }
                }
              }
            }
          }

          .itemLeft {
            img {
              width: 284px;
              height: 199px;
              margin: 29px;
            }
          }

          .itemRight {
            padding: 29px 29px 0 0;

            .itemTop {
              display: flex;
              justify-content: space-between;
              margin-bottom: 26px;

              h4 {
                height: 18px;
                font-size: 18px;
                font-weight: 400;
                color: #333;
                line-height: 39px;

                &:nth-child(2) {
                  height: 13px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #999999;
                  line-height: 39px;
                }
              }
            }

            p {
              height: 39px;
              font-size: 14px;
              font-weight: 400;
              line-height: 25px;
            }
          }
        }
      }

      .pageShow {
        // padding: 0 319px;
        width: 1280px;
        margin: 0 auto;
        margin-bottom: 120px;

        .page {
          margin-left: 1250px;
          display: flex;
          justify-content: space-between;

          span {
            display: block;
            width: 32px;
            height: 32px;
            line-height: 32px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            border-radius: 4px;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            // &:nth-child(9) {
            //   width: 89px;
            // }
          }
        }
      }
    }
  }
</style>